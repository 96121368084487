<template>
  <div class="app-container">
    <ShowTitle text="隱私政策" type="title" />
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
  data() {
    return {
      // imageUrl: null,
      // action: `${configCommon.apiHost}/api/upload/image`,
      // submitLoading: false,
      // dataObj: {},
    };
  },
};
</script>

<style lang="less" scoped>
</style>